import React, { useEffect, useMemo, useState } from "react";
import colors from "assets/theme/base/colors";
import { Box, Card, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import VuiSelect from "components/VuiSelect";
import VuiBox from "components/VuiBox";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import { getCategoryElements } from "api/stats";

function CarriageanalyticsChart() {
  const { info, success } = colors;
  const [activeMonth, setActiveMonth] = useState();
  const [activeMaterial, setActiveMaterial] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [options, setOptions] = useState();

  useEffect(() => {
    setActiveMaterial(categoryOptions[[0]]);
    setActiveMonth(monthOptions[2]);
  }, []);

  useEffect(() => {
    if (!activeMaterial?.value) return;
    setLoading(true);
    getCategoryElements(activeMaterial?.value)
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [activeMaterial]);

  const activeData = useMemo(() => {
    const item = data?.find((item) => item?.month === activeMonth.value);
    setOptions(item?.data?.map((item) => item?.name));
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 300);
    return item;
  }, [activeMonth, data]);

  const lineChartOptionsGradient = useMemo(() => {
    return {
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: [success.main, info.main],

      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
        onDatasetHover: {
          style: {
            fontSize: "12px",
            fontFamily: undefined,
          },
        },
        theme: "dark",
      },
      xaxis: {
        categories: ["ВКЦ", "ВСЦ-2", "ВСЦ-3", "ВСЦ-1", "РКЦ"],
        show: true,
        labels: {
          show: true,
          style: {
            colors: "#A0AEC0",
            fontSize: "10px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: true,
        color: "black",
        labels: {
          show: true,
          style: {
            colors: "#A0AEC0",
            fontSize: "10px",
          },
        },
      },
      legend: {
        show: true,
        labels: {
          colors: ["#ffffff", "#ffffff"],
        },
      },

      grid: {
        strokeDashArray: 5,
        borderColor: "#56577A",
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      fill: {
        type: "solid",
        colors: [success.main, info.main],
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 3,
          columnWidth: "40px",
        },
      },
    };
  }, [options]);

  const monthOptions = [
    { value: "January", label: "Yanvar" },
    { value: "February", label: "Fevral" },
    { value: "March", label: "Mart" },
    { value: "April", label: "Aprel" },
    { value: "May", label: "May" },
    { value: "June", label: "Iyun" },
    { value: "July", label: "Iyul" },
    { value: "August", label: "Avgust" },
    { value: "September", label: "Sentabr" },
    { value: "October", label: "Oktabr" },
    { value: "November", label: "Noyabr" },
    { value: "December", label: "Dekabr" },
  ];

  const categoryOptions = [
    { label: "Rama", value: "rama" },
    { label: "Vagon aravasi", value: "Cart" },
    { label: "Kuzov", value: "Body" },
    { label: "Old va orqa devor", value: "endWall" },
    { label: "Yon devor", value: "SideWall" },
    { label: "Lyuk qopqog'i", value: "CoverLuxe" },
    { label: "Qovurg'ali balka", value: "SpinalBeam" },
    { label: "Tormoz tizimi", value: "BrakeSystem" },
    { label: "G'ildirak juftliklar", value: "PairOFWheel" },
    { label: "Avtoulagich", value: "AutomicChainDevice" },
  ];

  const DropDownComponent = () => {
    return (
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{ color: "#fff", fontWeight: "500", fontSize: "1.125rem" }}>
          2024-yil zavodlarda mavjud ehtiyot qismlari soni
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "1rem",
            mb: 1,
          }}
          mt={1}
        >
          <Box sx={{ width: "200px" }}>
            <VuiSelect
              value={activeMaterial}
              onChange={(e) => setActiveMaterial(e)}
              options={categoryOptions || []}
              placeholder="Ehtiyot qism turini tanlang"
            />
          </Box>
          <Box sx={{ width: "200px" }}>
            <VuiSelect
              value={activeMonth}
              onChange={(e) => setActiveMonth(e)}
              fullWidth
              options={monthOptions || []}
              placeholder="Oyni tanlang"
            />
          </Box>
        </Box>
      </Stack>
    );
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <Card padding={0} sx={{ minHeight: "25rem" }}>
          <DropDownComponent />

          {loading ? (
            <VuiBox
              width="100%"
              height="100px"
              sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <CircularProgress />
            </VuiBox>
          ) : data?.length ? (
            <VerticalBarChart
              // title="Zavodlarga yetib kelayotgan ehtiyot qismlari"
              data={[
                {
                  name: "Tasdiqlangan (ta)",
                  data: activeData?.data?.map((item) => {
                    let sum = 0;
                    item?.factory_analysis?.forEach((i) => {
                      if (i?.status) sum += i?.total_count || 0;
                    });
                    return sum;
                  }),
                },
                {
                  name: "Tasdiqlanmagan (ta)",
                  data: activeData?.data?.map((item) => {
                    let sum = 0;
                    item?.factory_analysis?.forEach((i) => {
                      if (!i?.status) sum += i?.total_count || 0;
                    });
                    return sum;
                  }),
                },
              ]}
              options={lineChartOptionsGradient}
            />
          ) : null}
        </Card>
      </Grid>
    </>
  );
}

export default CarriageanalyticsChart;
