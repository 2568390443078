import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import VuiBox from "components/VuiBox";
import { Typography } from "@mui/material";
import CarriageInformations from "./CarriageInformations";
import { useMemo } from "react";
import VuiTypography from "components/VuiTypography";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icon}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

// const steps = ["Select campaign settings", "Create an ad group", "Create an ad"];

const steps = [
  {
    id: 1,
    name: "X. Balkani yig'ish va payvandlash",
    type: "single",
  },
  {
    id: 2,
    name: "Ramani yig'ish va payvandlash",
    type: "single",
  },
  {
    id: 3,
    name: "Kuzov qismini yig'ish",
    type: "collapse",
  },
  {
    id: 4,
    name: "Lyuk qopqoqlarini o'rnatish",
    type: "single",
  },
  {
    id: 5,
    name: "Tormoz uskunasini o'rnatish",
    type: "single",
  },
  {
    id: 6,
    name: "Avtoulagich uskunasini o'rnatish",
    type: "single",
  },
  {
    id: 7,
    name: "Vagonga rang berish",
    type: "single",
  },
  {
    id: 8,
    name: "Jo'natishga tayyor",
    type: "single",
  },
];

export default function ProducingProcess({ activeCarriage }) {
  const activeStep = useMemo(() => {
    return parseInt(activeCarriage?.step?.match(/\d+/)[0]);
  }, [activeCarriage]);

  return (
    <>
      <VuiBox>
        <p style={{ color: "white" }}>{activeCarriage?.number} raqamli vagon</p>
        <Stepper
          alternativeLabel
          activeStep={activeStep - 1}
          sx={{ margin: "1rem 0" }}
          connector={<ColorlibConnector />}
        >
          {steps.map((step) => (
            <Step key={step?.name}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <VuiTypography color="white" fontSize="15px">
                  {step?.name}
                </VuiTypography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <VuiBox sx={{ marginTop: 5 }}>
          <CarriageInformations step={activeStep} data={activeCarriage} />
        </VuiBox>
      </VuiBox>
    </>
  );
}
