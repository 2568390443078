import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import vagon from "assets/images/carriage_remove_bg.png";

//icons
import { IoClose } from "react-icons/io5";

//styles
import "./style.css";
import { Box, Card, Button, Grid, List, ListItem, Stack, Typography, Tabs, Tab, Icon } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useHttp } from "hooks/use-http";
import Loader from "components/Loader";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";
import VuiButton from "components/VuiButton";


export default function HalfCarriage() {
  const { request } = useHttp();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [activeId, setActiveId] = useState("");

  const requiredData = useMemo(() => {
    const reqData = [];
    if (data?.id) {
      reqData.push(data?.e_journal[1]);
      reqData.push(data?.e_journal[3]);
      reqData.push(data?.e_journal[2]);
    }
    return reqData;
  }, [data]);

  const sortedData = useMemo(() => {
    const readyData = [];
    if (requiredData?.length) {
      for (let i = 0; i < requiredData[0].json_data?.fields?.length; i++) {
        const data1 = [];
        requiredData?.map((item) => {
          data1.push(item?.json_data?.fields[i]);
        });
        readyData.push(data1);
      }
    }
    return readyData;
  }, [requiredData]);

  useEffect(() => {
    if (activeId) {
      setLoading(true);
      request({ url: `/vagon/parts/?journal_id=${activeId}` }).then((data) => {
        setData(data);
        setLoading(false);
      });
    }
  }, [activeId]);


  const [value, setValue] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(activeId)

  useEffect(() => {
    setActiveId(ids[value])
  }, [value])

  const ids = [132, 113, 116, 115, 137, 114]

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const headers = ["Material nomi va markasi", "Miqdori",]


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox >
        <VuiTypography fontSize={"2.25rem"} fontWeight={700} color={"white"}>
          Yarim vagon
        </VuiTypography>
        <Box sx={{ width: '100%', maxWidth: "90vw", overflow: "auto" }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="1.Rama" {...a11yProps(0)} />
              <Tab label="2.Kuzov" {...a11yProps(1)} />
              <Tab label="3.Tormoznoy sistema" {...a11yProps(2)} />
              <Tab label="4.Avtostepoye ustroystvo" {...a11yProps(3)} />
              <Tab label="5.Kalyosnaya para" {...a11yProps(4)} />
              <Tab label="6.Telyashka" {...a11yProps(5)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <VuiButton color="info" variant="text" onClick={() => { setActiveId(133) }} >1.1 Xrebtovaya balka</VuiButton>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <VuiButton color="info" variant="text" onClick={() => { setActiveId(134) }}>2.1 Stena bokovaya </VuiButton>
            <VuiButton color="info" variant="text" onClick={() => { setActiveId(135) }}>2.2 Stena tortsevaya</VuiButton>
            <VuiButton color="info" variant="text" onClick={() => { setActiveId(136) }}>2.3 Krishka lyuka</VuiButton>
          </CustomTabPanel>
        </Box>
        <Grid container spacing={3}>

          <Grid item sx={12} lg={8}>
            <VuiBox sx={{ position: "relative", width: "100%", height: "40rem" }} className="scale">
              <img src={vagon} style={{ width: "100%", height: "100%", objectFit: "contain" }} />
            </VuiBox>
          </Grid>
          <Grid item sx={12} lg={4}>
            <Card sx={{ height: "40rem", overflow: "auto" }}>
              <List sx={{ padding: 2, }}>
                <ListItem sx={{ py: 1, borderBottom: "1px dashed gray" }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <VuiTypography mr={2} fontSize={14} color="white" fontWeight={100}>
                      {headers[0]}
                    </VuiTypography>
                    <Stack direction="row">
                      <VuiTypography mr={0.5} fontSize={14} color="white" fontWeight={100}>
                        {headers[1]}
                      </VuiTypography>
                      <VuiTypography fontSize={14} color="white" fontWeight={100}>
                        {headers[2]}
                      </VuiTypography>
                    </Stack>
                  </Stack>
                </ListItem>
                {loading ? <Loader /> : sortedData?.map((data) => (
                  <ListItem sx={{ py: 1, borderBottom: "1px dashed gray" }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <VuiTypography mr={2} fontSize={14} color="white" fontWeight={100}>
                        {data?.find((_, index) => index === 0)}
                      </VuiTypography>
                      <Stack direction="row">
                        <VuiTypography mr={0.5} fontSize={14} color="white" fontWeight={100}>
                          {data?.find((_, index) => index === 1)}
                        </VuiTypography>
                        <VuiTypography fontSize={14} color="white" fontWeight={100}>
                          {data?.find((_, index) => index === 2)}
                        </VuiTypography>
                      </Stack>
                    </Stack>
                  </ListItem>
                ))}

              </List>
            </Card>
          </Grid>

        </Grid>

      </VuiBox>

    </DashboardLayout>
  );
}
