import { Button } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { useMemo } from "react";

export default function CarriageList({ rows, setActiveProcess }) {
  const tableData = useMemo(() => {
    return {
      columns: [
        { Header: "Vagon raqami", accessor: "number" },
        { Header: "Vagon turi", accessor: "type" },
        { Header: "Holati", accessor: "process" },
        { Header: "Harakatlar", accessor: "actions" },
      ],
      rows,
    };
  }, [rows]);

  return (
    <DataTable
      table={tableData}
      canSearch
      entriesPerPage={false}
      setActiveProcess={setActiveProcess}
    />
  );
}
