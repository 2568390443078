import React, { useEffect, useMemo, useState } from "react";
import colors from "assets/theme/base/colors";
import { Card, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import { getCarriageAnalytics } from "api/stats";

const months = [
  "Yanvar",
  "Fevral",
  "Mart",
  "Aprel",
  "May",
  "Iyun",
  "Iyul",
  "Avgust",
  "Sentabr",
  "Oktabr",
  "Noyabr",
  "Dekabr",
];

function GradientLineChartPr() {
  const { white, info } = colors;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCarriageAnalytics()
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const lineChartOptionsGradient = useMemo(() => {
    return {
      chart: {
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        theme: "dark",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: months,
        labels: {
          style: {
            colors: "#c8cfca",
            fontSize: "10px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c8cfca",
            fontSize: "10px",
          },
        },
      },
      legend: {
        show: true,
        labels: {
          colors: ["#fff", "#fff"],
        },
      },
      grid: {
        strokeDashArray: 5,
        borderColor: "#56577A",
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0,
          gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          inverseColors: true,
          opacityFrom: 0.8,
          opacityTo: 0,
          stops: [],
        },
        colors: [info.main, white.main],
      },
      colors: [info.main, white.main],
    };
  }, [data]);

  function aggregateByMonth(data) {
    const aggregatedData = {};

    // Iterate over each object in the data array
    data.forEach((entry) => {
      const dateParts = entry.sana.split("-");

      const month = months[+dateParts[1] - 1];

      // If the month exists in the aggregatedData, add the values; otherwise, initialize it
      if (aggregatedData[month]) {
        aggregatedData[month].produced_wagons += entry.produced_wagons;
        aggregatedData[month].approved_wagons += entry.approved_wagons;
      } else {
        aggregatedData[month] = {
          produced_wagons: entry.produced_wagons,
          approved_wagons: entry.approved_wagons,
        };
      }
    });

    return aggregatedData;
  }

  const chartData = useMemo(() => {
    if (!data?.length) return;
    return aggregateByMonth(data);
  }, [data]);

  return (
    <>
      <Grid item xs={12} lg={8}>
        <Card padding={0} sx={{ minHeight: "25rem" }}>
          <Typography color="#fff" fontWeight={500} fontSize="1.125rem">
            2024-yil vagonlar ishlab chiqarish tahlili
          </Typography>
          {loading ? (
            <Stack
              height="100px"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <CircularProgress />
            </Stack>
          ) : data?.length ? (
            <GradientLineChart
              data={[
                {
                  name: "Hammasi (ta)",
                  data: Object.values(chartData)?.map((item) => item?.produced_wagons),
                },
                {
                  name: "Maqullanganlar (ta)",
                  data: Object.values(chartData)?.map((item) => item?.approved_wagons),
                },
              ]}
              options={lineChartOptionsGradient}
            />
          ) : null}
        </Card>
      </Grid>
    </>
  );
}

export default GradientLineChartPr;
