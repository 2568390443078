import { Grid, Typography, Card, Stack, Box } from "@mui/material";
import { getPartsAnalytics } from "api/stats";
import VuiSelect from "components/VuiSelect";
import { useEffect, useMemo, useState } from "react";
import colors from "assets/theme/base/colors";
import PieChart from "examples/Charts/PieChart";
import { getMainParts } from "api/stats";
import Loader from "components/Loader";

function PartAnalyticsPieChart() {
  const [loading, setLoading] = useState(false);
  const [elementLoading, setElementLoading] = useState(false);
  const [mainElements, setMainElements] = useState();
  const [activeElement, setActiveElement] = useState();
  const [activeMonth, setActiveMonth] = useState();
  const [activeElementData, setActiveElementData] = useState();
  const [chartData, setChartData] = useState();
  const { info } = colors;

  useEffect(() => {
    setElementLoading(true);
    getMainParts()
      .then((data) => {
        setMainElements(data?.elements);
        setElementLoading(false);
      })
      .catch(() => {
        setElementLoading(false);
      });
    setActiveMonth(monthOptions[2]);
  }, []);

  useEffect(() => {
    if (!activeElement?.value) return;
    setLoading(true);
    getPartsAnalytics(activeElement?.value)
      .then((data) => {
        setLoading(false);
        setActiveElementData(data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [activeElement]);

  const elementsOptions = useMemo(() => {
    if (elementLoading) return [{ value: "loading", label: "Yuklanmoqda", isDisabled: true }];
    if (!mainElements?.length) return [];
    const options = mainElements?.map((item) => ({ value: item?.id, label: item?.content }));
    setActiveElement(options[0]);
    return options;
  }, [mainElements, elementLoading]);

  const monthOptions = [
    { value: 0, label: "Yanvar" },
    { value: 1, label: "Fevral" },
    { value: 2, label: "Mart" },
    { value: 3, label: "Aprel", isDisabled: true },
    { value: 4, label: "May", isDisabled: true },
    { value: 5, label: "Iyun", isDisabled: true },
    { value: 6, label: "Iyul", isDisabled: true },
    { value: 7, label: "Avgust", isDisabled: true },
    { value: 8, label: "Sentabr", isDisabled: true },
    { value: 9, label: "Oktabr", isDisabled: true },
    { value: 10, label: "Noyabr", isDisabled: true },
    { value: 11, label: "Dekabr", isDisabled: true },
  ];

  const pieChartOptions = useMemo(() => {
    return {
      labels: ["Ish jarayonida", "Tayyorlangan"],
      colors: ["#0075FF", "#2CD9FF"],
      chart: {
        width: "100%",
        stroke: {
          show: false,
        },
      },
      legend: {
        show: true,
        formatter: function (label, opts) {
          return label + " - " + opts.w.globals.series[opts.seriesIndex] + " ta";
        },
        position: "bottom",
        labels: {
          colors: ["#ffffff", "#ffffff"],
        },
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      stroke: {
        show: false,
      },
      dataLabels: {
        enabled: true,
      },
      hover: { mode: null },
      plotOptions: {
        donut: {
          expandOnClick: false,
          donut: {
            labels: {
              show: false,
            },
          },
        },
      },
      fill: {
        colors: ["#0075FF", "#2CD9FF"],
      },
      tooltip: {
        enabled: true,
        theme: "dark",
      },
    };
  }, []);

  const activeData = useMemo(() => {
    if (!activeElementData?.data) return [0, 0];
    const filteredData = activeElementData?.data?.find((_, index) => index === activeMonth?.value);
    return [filteredData?.ish_jarayonida || 0, filteredData?.tayyor || 0];
  }, [activeMonth, activeElementData]);

  const DropDownComponent = () => {
    return (
      <Stack flexDirection="column" justifyContent="space-between" alignItems="center">
        <Typography sx={{ color: "#fff", fontWeight: "500", fontSize: "1.125rem" }}>
          2024-yil asosiy qismlar tahlili
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "1rem",
            mb: 1,
          }}
          mt={1}
        >
          <Box sx={{ width: "120px" }}>
            <VuiSelect
              value={activeElement}
              onChange={(e) => {
                setActiveElement(e);
                setChartData([0, 0]);
              }}
              options={elementsOptions}
              placeholder="Ehtiyot qism"
            />
          </Box>
          <Box>
            <VuiSelect
              value={activeMonth}
              onChange={(e) => {
                setActiveMonth(e);
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 300);
              }}
              options={monthOptions}
              placeholder="Oy"
            />
          </Box>
        </Box>
      </Stack>
    );
  };

  useEffect(() => {
    setChartData(activeData);
  }, [activeData]);

  return (
    <Grid item xs={12} lg={4}>
      <Card sx={{ minHeight: "25rem" }}>
        <DropDownComponent />
        {loading || elementLoading ? (
          <Loader />
        ) : chartData?.length ? (
          chartData[0] === 0 && chartData[1] === 0 ? (
            <Typography fontSize="1rem" color="white">
              Ma'lumot topilmadi
            </Typography>
          ) : (
            <>
              <PieChart data={activeData} options={pieChartOptions} />
            </>
          )
        ) : null}
      </Card>
    </Grid>
  );
}

export default PartAnalyticsPieChart;
