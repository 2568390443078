/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { useMemo, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// Vision UI Dashboard PRO React components
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";

// Vision UI Dashboard PRO React example components
import SalesTableCell from "examples/Tables/SalesTable/SalesTableCell";
import VuiButton from "components/VuiButton";
import { Collapse } from "@mui/material";

function SalesTable({ title, rows }) {
  const [showAll, setShowAll] = useState(false)

  const toggleShow = () => {
    setShowAll(!showAll)
  }

  const tableCells = useMemo(() => {
    return [...rows?.KZ, ...rows.UAE]
  }, [rows])

  const renderTableCells = tableCells.map((row, key) => {
    const tableRows = [];
    const rowKey = `row-${key}`;

    Object.entries(row).map(([cellTitle, cellContent]) =>
      Array.isArray(cellContent)
        ? tableRows.push(
          <SalesTableCell
            key={cellContent[1]}
            title={cellTitle}
            content={cellContent[1]}
            image={cellContent[0]}
            noBorder={key === rows.length - 1}
          />
        )
        : tableRows.push(
          <SalesTableCell
            key={cellContent}
            title={cellTitle}
            content={cellContent}
            noBorder={key === rows.length - 1}
          />
        )
    );

    return <TableRow key={rowKey}>{tableRows}</TableRow>;
  });

  return (
    <>
      <TableContainer sx={{ overflow: "hiden", transition: "300ms all ease-in" }}>
        <Collapse in={showAll} collapsedSize={"230px"}>
          <Table>
            <TableHead>
              <VuiBox component="tr" width="max-content" display="block" mb={1.5}>
                <VuiTypography variant="lg" component="td" color="white" fontWeight="bold">
                  {title}
                </VuiTypography>
              </VuiBox>
            </TableHead>
            <TableBody>{useMemo(() => renderTableCells, [rows])}</TableBody>
          </Table>
        </Collapse>

      </TableContainer>
      <VuiButton onClick={toggleShow} variant="text" color="info" sx={{ textTransform: "none", marginTop: 2 }}>{!showAll ? "Davomi" : "Yopish"}</VuiButton>
    </>
  );
}

// Setting default values for the props of SalesTable
SalesTable.defaultProps = {
  rows: [{}],
};

// Typechecking props for the SalesTable
SalesTable.propTypes = {
  title: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default SalesTable;
