import { useHttp } from "hooks/use-http";

export const getVagonList = (id) => {
  const { request } = useHttp();
  return request({ url: `/vagon/parts/?journal_id=${id}` });
};

export const getVirtualMapElements = (id) => {
  const { request } = useHttp();
  return request({ url: `/vagon/parts/?journal_id=${id}` });
};
