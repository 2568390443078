import { Grid, Typography, Card, Stack, Box } from "@mui/material";
import VuiSelect from "components/VuiSelect";
import { useEffect, useMemo, useState } from "react";
import PieChart from "examples/Charts/PieChart";
import Loader from "components/Loader";
import { getDetailedParts } from "api/stats";
import { getAnalytics } from "api/stats";

function DetailedPartsPieChart() {
  const [loading, setLoading] = useState(false);
  const [elementLoading, setElementLoading] = useState(false);
  const [mainElements, setMainElements] = useState();
  const [activeElement, setActiveElement] = useState();
  const [activeElementData, setActiveElementData] = useState();
  const [chartData, setChartData] = useState();
  const [unit, setUnit] = useState("");

  useEffect(() => {
    setElementLoading(true);
    getDetailedParts()
      .then((data) => {
        setMainElements(data?.elements);
        setElementLoading(false);
      })
      .catch(() => {
        setElementLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!activeElement?.value) return;
    setLoading(true);
    getAnalytics(activeElement?.value)
      .then((data) => {
        setLoading(false);
        setActiveElementData(data);
        setUnit(data?.unit);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [activeElement]);

  const elementsOptions = useMemo(() => {
    if (elementLoading) return [{ value: "loading", label: "Yuklanmoqda", isDisabled: true }];
    if (!mainElements?.length) return [];
    const options = mainElements?.map((item) => ({ value: item?.id, label: item?.content }));
    setActiveElement(options[0]);
    return options;
  }, [mainElements, elementLoading]);

  const pieChartOptions = useMemo(() => {
    return {
      labels: ["Mavjud miqdor", "Topshirilgan miqdor"],
      colors: ["#2cd9ff", "#0e96ff"],
      chart: {
        width: "100%",
        stroke: {
          show: false,
        },
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      legend: {
        show: true,
        formatter: function (label, opts) {
          return label + " - " + opts.w.globals.series[opts.seriesIndex] + " " + unit;
        },
        labels: {
          colors: ["#ffffff", "#ffffff"],
        },
      },
      stroke: {
        show: false,
      },
      dataLabels: {
        enabled: true,
      },
      hover: { mode: null },
      plotOptions: {
        donut: {
          expandOnClick: false,
          donut: {
            labels: {
              show: false,
            },
          },
        },
      },
      fill: {
        colors: ["#2cd9ff", "#0e96ff"],
      },
      tooltip: {
        enabled: true,
        theme: "dark",
      },
    };
  }, [unit]);

  const activeData = useMemo(() => {
    if (!activeElementData?.qism_nomi) return [0, 0];
    return [+activeElementData?.mavjud_miqdori || 0, +activeElementData?.topshirilgan_miqdori || 0];
  }, [activeElementData]);

  const DropDownComponent = () => {
    return (
      <Stack flexDirection="column" justifyContent="space-between" alignItems="center">
        <Typography sx={{ color: "#fff", fontWeight: "500", fontSize: "1.125rem" }}>
          2023-2024-yil ehtiyot qismlar tahlili
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "1rem",
            mb: 1,
          }}
          mt={1}
        >
          <Box sx={{ width: "250px" }}>
            <VuiSelect
              value={activeElement}
              onChange={(e) => {
                setActiveElement(e);
                setChartData([0, 0]);
                setUnit("");
              }}
              options={elementsOptions}
              placeholder="Ehtiyot qism turini tanlang"
            />
          </Box>
        </Box>
      </Stack>
    );
  };

  useEffect(() => {
    setChartData(activeData);
  }, [activeData]);

  return (
    <Grid item xs={12} lg={6}>
      <Card sx={{ minHeight: "25rem" }}>
        <DropDownComponent />
        {loading || elementLoading ? (
          <Loader />
        ) : chartData?.length && unit ? (
          chartData[0] === 0 && chartData[1] === 0 ? (
            <Typography fontSize="1rem" color="white">
              Ma'lumot topilmadi
            </Typography>
          ) : (
            <>
              <PieChart data={activeData} options={pieChartOptions} />
            </>
          )
        ) : null}
      </Card>
    </Grid>
  );
}

export default DetailedPartsPieChart;
