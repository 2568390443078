/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SalesTable from "examples/Tables/SalesTable";
// Data
// import salesTableData from "layouts/dashboards/default/data/salesTableData";
import { KZ, UAE } from "./data/salesTableData";

// icons
import GradientLineChartPr from "layouts/pages/charts/components/carriageanalyticsChart";
import CarriageanalyticsChart from "layouts/pages/charts/components/gradientLineChartPr";
import PartAnalyticsPieChart from "layouts/pages/charts/components/partAnalyticsPieChart";
import DetailedPartsPieChart from "layouts/pages/charts/components/detailedPartsPieChart";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import { IoConstruct, IoWallet } from "react-icons/io5";
import { GiProgression } from "react-icons/gi";
import { TbCoins } from "react-icons/tb";
import CreditBalance from "layouts/pages/account/billing/components/CreditBalance";
import VuiButton from "components/VuiButton";

//images

function Default() {
  const { values } = breakpoints;


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3} p={1}>
          <VuiTypography
            variant={window.innerWidth < values.sm ? "h3" : "h2"}
            textTransform="capitalize"
            fontWeight="bold"
            color="white"
          >
            umumiy statistika
          </VuiTypography>
        </VuiBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <VuiBox mb={3}>
              <MiniStatisticsCard
                title={{ text: "ishlab chiqarilgan vagonlar", fontWeight: "bold" }}
                count="376 ta"
                percentage={{ color: "success" }}
                icon={<IoConstruct color="white" />}
              />
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <VuiBox>
              <MiniStatisticsCard
                title={{ text: "Sotilgan vagonlar", fontWeight: "bold" }}
                count="421 ta"
                percentage={{ color: "success" }}
                icon={<TbCoins color="white" />}
              />
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <VuiBox mb={3}>
              <MiniStatisticsCard
                title={{ text: "Umumiy sotuv", fontWeight: "bold" }}
                count="136 mlrd so'm"
                // percentage={{ color: "error", text: "-2%" }}
                icon={<IoWallet color="white" />}
              />
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={3}>
            <VuiBox mb={3}>
              <MiniStatisticsCard
                title={{ text: "Joriy yilgi reja", fontWeight: "bold" }}
                count="1351 ta"
                // percentage={{ color: "success", text: "+5%" }}
                icon={<GiProgression color="white" />}
              />
            </VuiBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Card>
              <SalesTable title="Davlatlar bo'yicha sotuvlar" rows={{ KZ, UAE }} />
            </Card>
          </Grid>

          <Grid item xs={12} lg={6}>
            <CreditBalance title="Mahalliy sotuvlar" />
          </Grid>

        </Grid>
        <Grid container spacing={3} mt={3}>
          <GradientLineChartPr />
          <PartAnalyticsPieChart />
          <DetailedPartsPieChart />
          <CarriageanalyticsChart />
        </Grid>
      </VuiBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Default;
