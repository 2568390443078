import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";

//import images
import virtualMap from "assets/images/111.png";
import p1 from "assets/images/1.png";
import p2 from "assets/images/2.png";
import p3 from "assets/images/3.png";
import p4 from "assets/images/4.1.png";
import p41 from "assets/images/4.2.png";
import p5 from "assets/images/5.png";
import p6 from "assets/images/6.png";
import p7 from "assets/images/7.png";
import stellaj from "assets/images/stellaj.png";
import dwn from "assets/images/download1.png";
import dwn2 from "assets/images/download.png";

import st1 from "assets/images/rectangle.png";
import old_kond from "assets/images/Old_devor_kond1.png";
import old from "assets/images/Old_devor1.png";
import old_devor_stellaj from "assets/images/Old_devor_stellaj1.png";
import lyuk_yigish from "assets/images/Lyuk_yigish1.png";
import zina from "assets/images/Zina1.png";
import tualet from "assets/images/Tualet1.png";
import paddon1 from "assets/images/Paddon1.png";
import tarakror from "assets/images/Tarakror1.png";
import bgbg1 from "assets/images/bgbg1.png";
import s11 from "assets/images/s1.1.png";
import s12 from "assets/images/s1.2.png";
import tayyor from "assets/images/Tayyor1.png";
import avtosepka1 from "assets/images/Avtosepka1.1.png";
import avtosepka2 from "assets/images/Avtosepka1.2.png";
import sv1 from "assets/images/sv1.png";

import lyuk from "assets/images/Lyuk_kontavatel1.png";
import { Card, Grid, Stack, List, ListItem, Typography } from "@mui/material";
import { getVirtualMapElements } from "api/vagon";
import VuiTypography from "components/VuiTypography";
import Loader from "components/Loader";

import "./style.css";

const storeIds = [121, 120, 119, 123, 122, 118];

function VirtualMap() {
  const [id, setId] = useState(null);
  const [activeClass, setActiveClass] = useState();
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState([0, 0]);
  const [data, setData] = useState();
  const [storeData, setStoreData] = useState([]);
  const [storeId, setStoreId] = useState();

  const cardRef = useRef();

  const handleClick = (e) => {
    const elem = e.target;
    const storeClassId = elem.classList[0];
    setActiveClass(storeClassId);
    if (elem.id) {
      setId(elem.id);
      setStoreId(null);
    } else if (+storeClassId) {
      setStoreId(+storeClassId);
      setId(null);
    } else {
      setId(null);
      setStoreId(null);
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (!id && !storeId) return;
    let rect = {};
    if (id) {
      const postX = document?.getElementById(id).getBoundingClientRect();
      if (Math.floor(+id) === 25 || Math.floor(+id) === 24) {
        rect = { left: postX.left - 200, top: postX.top };
      } else {
        rect = postX;
      }
    } else if (storeId) {
      const post = document.getElementsByClassName(String(storeId))[0].getBoundingClientRect();
      if (Math.floor(storeId) === 118) {
        rect = { left: post.left, top: post.top - 170 };
      } else {
        rect = post;
      }
    } else {
      rect = { left: 0, top: 0 };
    }
    const position = [rect.left + window.scrollX, rect.top + window.scrollY];
    setPosition(position);
    setIsVisible(true);
  }, [id, storeId]);

  useEffect(() => {
    const data = [];
    setLoading(true);
    getVirtualMapElements(117).then((res) => setData(res));
    storeIds?.map((item) => {
      getVirtualMapElements(item)
        .then((res) => {
          data.push({ name: res?.jr_name, fields: res?.e_journal, id: res?.id });
        })
        .then(() => setLoading(false));
    });
    setStoreData(data);
  }, []);

  const activeImageData = useMemo(() => {
    if (data?.id && id) {
      const dat = data?.e_journal?.find((_, index) => index === Math.floor(+id));
      return { name: dat?.field_name, value: dat?.json_data?.fields[0] };
    }
  }, [data, id]);

  const defineStoreData = useMemo(() => {
    if (+storeId && storeData?.length) {
      const readyData = [];
      const activeStore = storeData?.find((item) => {
        return item?.id === Math.floor(storeId);
      });
      const newFields = [activeStore?.fields[0], activeStore?.fields[2], activeStore?.fields[1]];
      for (
        let i = 0;
        i < newFields?.find((_, index) => index === 0)?.json_data?.fields?.length;
        i++
      ) {
        const data = [];
        newFields?.map((item) => data.push(item?.json_data?.fields[i]));
        readyData.push(data);
      }
      return {
        name: activeStore?.name,
        data: readyData,
      };
    }
  }, [storeData, storeId]);

  if (loading) return <Loader />;

  return (
    <div
      onClick={(e) => {
        handleClick(e);
      }}
    >
      <DashboardLayout>
        <DashboardNavbar />
        <VuiBox
          sx={{
            width: "100%",
            position: "relative",
            mt: 6,
            flexGrow: 1,
            height: "800px",
          }}
        >
          <VuiTypography color="white" variant="h3" mb={3}>
            Zavodning hozirgi holatdagi virtual xaritasi
          </VuiTypography>

          <Grid container sx={{ height: "100%" }}>
            <Grid item xs={4} sx={{ height: "100%" }}>
              <VuiBox sx={{ minWidth: "100%", height: "100%" }}>
                <Grid container sx={{ height: "50%", border: "1px solid gray" }}>
                  <Grid item xs={5}>
                    <VuiBox p={1} sx={{ border: "1px solid", height: "100%" }}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <VuiBox sx={{ height: "30px" }}>
                            <img
                              src={st1}
                              className="121"
                              style={{ width: "100%", height: "100%" }}
                              alt=""
                            />
                          </VuiBox>
                        </Grid>
                        <Grid item xs={6}>
                          <VuiBox sx={{ height: "30px" }}>
                            <img
                              src={st1}
                              className="121"
                              style={{ width: "100%", height: "100%" }}
                              alt=""
                            />
                          </VuiBox>
                        </Grid>
                        <Grid item xs={12} my={2}>
                          <VuiBox sx={{}} px={2}>
                            <img src={lyuk_yigish} id="17" alt="" style={{ width: "100%" }} />
                          </VuiBox>
                        </Grid>
                        <Grid item xs={6} mb={2}>
                          <VuiBox sx={{}}>
                            <img
                              src={lyuk}
                              id="18.1"
                              style={{ width: "100%", height: "100%" }}
                              alt=""
                            />
                          </VuiBox>
                        </Grid>
                        <Grid item xs={6} mb={2}>
                          <VuiBox sx={{}}>
                            <img
                              src={lyuk}
                              id="18.2"
                              style={{ width: "100%", height: "100%" }}
                              alt=""
                            />
                          </VuiBox>
                        </Grid>
                        <Grid mt="10px" item xs={3}>
                          <VuiBox>
                            <img width="100%" height="100%" id="19.6" src={stellaj} alt="" />
                          </VuiBox>
                        </Grid>
                        <Grid mt="10px" item xs={3}>
                          <VuiBox>
                            <img width="100%" height="100%" id="19.5" src={stellaj} alt="" />
                          </VuiBox>
                        </Grid>
                        <Grid mt="10px" item xs={3}>
                          <VuiBox>
                            <img width="100%" height="100%" id="19.4" src={stellaj} alt="" />
                          </VuiBox>
                        </Grid>
                        <Grid mt="10px" item xs={3}>
                          <VuiBox>
                            <img width="100%" height="100%" id="19.3" src={stellaj} alt="" />
                          </VuiBox>
                        </Grid>
                      </Grid>
                    </VuiBox>
                  </Grid>
                  <Grid item xs={7}>
                    <VuiBox sx={{ border: "1px solid", height: "100%" }} p={1}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <VuiBox sx={{ width: "80px", height: "40px" }}>
                            <img
                              src={st1}
                              className="120"
                              style={{ width: "100%", height: "100%" }}
                              alt=""
                            />
                          </VuiBox>
                        </Grid>
                        <Grid item xs={5}>
                          <VuiBox mt={4}>
                            <img src={old_kond} id="20" alt="old" style={{ width: "100%" }} />
                          </VuiBox>
                        </Grid>
                        <Grid item xs={7}>
                          <Stack justifyContent="space-between" sx={{ height: "200px" }}>
                            <VuiBox sx={{}}>
                              <img src={old} id="21.1" alt="old" style={{ width: "100%" }} />
                            </VuiBox>
                            <VuiBox sx={{}}>
                              <img src={old} id="21.2" alt="old" style={{ width: "100%" }} />
                            </VuiBox>
                          </Stack>
                        </Grid>
                        <Grid item xs={3}>
                          <VuiBox sx={{ height: "65px" }}>
                            <img
                              id="22.1"
                              src={old_devor_stellaj}
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                            />
                          </VuiBox>
                        </Grid>
                        <Grid item xs={3}>
                          <VuiBox sx={{ height: "65px" }}>
                            <img
                              id="22.2"
                              src={old_devor_stellaj}
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                            />
                          </VuiBox>
                        </Grid>
                        <Grid item xs={3}>
                          <VuiBox sx={{ height: "65px" }}>
                            <img
                              id="22.3"
                              src={old_devor_stellaj}
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                            />
                          </VuiBox>
                        </Grid>
                        <Grid item xs={3}>
                          <VuiBox sx={{ height: "65px" }}>
                            <img
                              id="22.4"
                              src={old_devor_stellaj}
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                            />
                          </VuiBox>
                        </Grid>
                      </Grid>
                    </VuiBox>
                  </Grid>
                </Grid>
                <Grid container sx={{ height: "50%", border: "1px solid gray" }}>
                  <Grid item xs={12}>
                    <VuiBox pt={2} pr={2}>
                      <Grid container spacing={2} justifyContent="space-between">
                        <Grid item xs={2} mt={3}>
                          <img src={zina} alt="" style={{ width: "100%" }} />
                        </Grid>
                        <Grid item xs={8}>
                          <img src={p2} id="15" style={{ width: "100%" }} alt="" />
                        </Grid>
                        <Grid item xs={2}>
                          <VuiBox sx={{ height: "160px" }}>
                            <img
                              src={st1}
                              className="119"
                              style={{ width: "100%", height: "100%" }}
                              alt=""
                            />
                          </VuiBox>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="space-between" alignItems="flex-end">
                        <Grid item xs={5}>
                          <VuiBox sx={{}}>
                            <img src={tualet} alt="" style={{ width: "100%" }} />
                          </VuiBox>
                        </Grid>
                        <Grid item xs={5.5} mt={-6}>
                          <VuiBox sx={{}} mb={2}>
                            <img src={p6} id="16.1" style={{ width: "100%" }} alt="" />
                          </VuiBox>
                          <VuiBox sx={{}}>
                            <img src={p6} id="16.2" style={{ width: "100%" }} alt="" />
                          </VuiBox>
                        </Grid>
                      </Grid>
                    </VuiBox>
                  </Grid>
                </Grid>
              </VuiBox>
            </Grid>
            <Grid item xs={5}>
              <VuiBox sx={{ border: "1px solid gray", height: "50%" }} px={1} pt={1}>
                <Grid container spacing={2}>
                  <Grid item xs={4} py={2}>
                    <Stack
                      direction="row"
                      justifyContent="end"
                      spacing={5}
                      p={2}
                      sx={{ height: "100%", border: "1px solid" }}
                    >
                      <img
                        src={paddon1}
                        id="26.1"
                        style={{ width: "53px", height: "180px" }}
                        alt=""
                      />
                      <img
                        src={paddon1}
                        id="26.2"
                        style={{ width: "53px", height: "180px" }}
                        alt=""
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={5} py={2} pr={3}>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="end"
                      sx={{ height: "100%", border: "1px solid" }}
                      py={2}
                    >
                      <Stack direction="row" justifyContent="space-between">
                        <Stack mr={1} py={2} spacing={2}>
                          <VuiBox sx={{ width: "40px", height: "80px" }}>
                            <img
                              src={stellaj}
                              width="100%"
                              height="100%"
                              style={{ objectFit: "cover", transform: "rotate(180deg)" }}
                              alt=""
                              id="19.1"
                              className="stellaj_19"
                            />
                          </VuiBox>
                          <img
                            src={st1}
                            className="123"
                            style={{ width: "40px", height: "60px" }}
                            alt=""
                          />
                        </Stack>
                        <img
                          src={paddon1}
                          id="26.5"
                          style={{ width: "53px", height: "180px" }}
                          alt=""
                        />
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Stack mr={1} py={2} spacing={2}>
                          <VuiBox sx={{ width: "40px", height: "80px" }}>
                            <img
                              src={stellaj}
                              width="100%"
                              height="100%"
                              style={{ objectFit: "cover", transform: "rotate(180deg)" }}
                              alt=""
                              id="19.2"
                              className="stellaj_19"
                            />
                          </VuiBox>
                          <img
                            src={st1}
                            className="123"
                            style={{ width: "40px", height: "60px" }}
                            alt=""
                          />
                        </Stack>
                        <img
                          src={paddon1}
                          id="26.4"
                          style={{ width: "53px", height: "180px" }}
                          alt=""
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={3} py={2}>
                    <Stack
                      direction="row"
                      justifyContent="end"
                      p={2}
                      sx={{ height: "100%", border: "1px solid" }}
                    >
                      <Stack justifyContent="space-between" py={2} mr={2}>
                        <img
                          src={st1}
                          className="122"
                          style={{ width: "30px", height: "40px" }}
                          alt=""
                        />
                        <img
                          src={st1}
                          className="122"
                          style={{ width: "30px", height: "40px" }}
                          alt=""
                        />
                        <img
                          src={st1}
                          className="122"
                          style={{ width: "30px", height: "40px" }}
                          alt=""
                        />
                      </Stack>
                      <img
                        src={paddon1}
                        id="26.3"
                        style={{ width: "53px", height: "180px" }}
                        alt=""
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <VuiBox sx={{ border: "1px solid", width: "80%" }}>
                      <img
                        src={p3}
                        width="250px"
                        style={{ marginTop: "20px", marginLeft: "100px" }}
                        alt=""
                        id="23"
                      />
                    </VuiBox>
                  </Grid>
                </Grid>
              </VuiBox>
              <VuiBox sx={{ border: "1px solid gray", height: "50%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Stack
                      sx={{ width: "100%" }}
                      justifyContent="space-between"
                      spacing={4}
                      px={4}
                      py={3}
                    >
                      <img src={tarakror} style={{ width: "100%" }} id="1" alt="" />
                      <VuiBox sx={{ width: "100%", height: "20px" }}>
                        <img
                          src={dwn}
                          style={{ width: "100%", height: "100%", objectFit: "cover" }}
                          alt=""
                          id="0"
                        />
                      </VuiBox>
                      <VuiBox sx={{ width: "100%", height: "20px" }}>
                        <img
                          src={dwn2}
                          style={{ width: "100%", height: "100%", objectFit: "cover" }}
                          alt=""
                          id="2.1"
                        />
                      </VuiBox>
                      <img src={p5} style={{}} alt="" id="11.1" />
                      <Stack direction="row" spacing={3}>
                        <img src={bgbg1} style={{ width: "70%" }} alt="" id="14" />
                        <img src={s11} style={{ width: "30%", height: "100px" }} id="9" alt="" />
                        <img src={s12} style={{ width: "30%", height: "100px" }} id="10" alt="" />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack
                      sx={{ width: "100%", height: "100%" }}
                      justifyContent="space-between"
                      spacing={4}
                      px={4}
                      py={3}
                    >
                      <img src={tarakror} style={{ width: "100%" }} alt="" id="3" />
                      <VuiBox sx={{ width: "100%", height: "20px" }}>
                        <img
                          src={dwn2}
                          style={{ width: "100%", height: "100%", objectFit: "cover" }}
                          alt=""
                          id="2.2"
                        />
                      </VuiBox>
                      <img src={p7} alt="" id="12" />
                      <img src={p5} style={{}} alt="" id="11.2" />
                      <Stack direction="row" spacing={2}>
                        <img src={st1} className="118.1" style={{ width: "20%" }} alt="" />
                        <img src={st1} className="118.2" style={{ width: "20%" }} alt="" />
                        <img src={st1} className="118.3" style={{ width: "20%" }} alt="" />
                        <img src={st1} className="118.4" style={{ width: "20%" }} alt="" />
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </VuiBox>
            </Grid>
            <Grid item xs={3}>
              <VuiBox sx={{ py: 5, px: 4, border: "1px solid gray", height: "50%" }}>
                <Stack style={{ height: "100%" }} justifyContent="space-between">
                  <img src={tayyor} alt="" id="27.1" />
                  <Stack direction="row" justifyContent="center" spacing={2}>
                    <Stack direction="row" spacing={1}>
                      <img src={avtosepka1} style={{ width: "30%" }} id="24.1" alt="" />
                      <img src={avtosepka2} style={{ width: "60%" }} id="25.1" alt="" />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <img src={avtosepka1} style={{ width: "30%" }} id="24.2" alt="" />
                      <img src={avtosepka2} style={{ width: "60%" }} id="25.2" alt="" />
                    </Stack>
                  </Stack>
                  <img src={tayyor} id="27.2" alt="" />
                </Stack>
              </VuiBox>
              <VuiBox px={4} sx={{ height: "50%", border: "1px solid gray" }}>
                <Stack height="100%" justifyContent="space-between" pb={2}>
                  <img src={p41} style={{ width: "70%", margin: "0 auto" }} alt="" id="8" />
                  <img src={p4} style={{ width: "70%", margin: "0 auto" }} alt="" id="7" />
                  <img src={sv1} alt="" style={{ width: "90%", margin: "0 auto" }} id="5" />
                  <img src={p1} style={{ width: "75%", margin: "0 auto" }} id="13.1" alt="" />
                  <img src={p1} style={{ width: "75%", margin: "0 auto" }} id="13.2" alt="" />
                </Stack>
              </VuiBox>
            </Grid>
          </Grid>
        </VuiBox>
      </DashboardLayout>
      <VuiBox
        ref={cardRef}
        sx={{
          position: "absolute",
          left: `${position[0]}px`,
          top: `${position[1]}px`,
          tranform: "translateX(-50%)",
          transform: "translateY(-50%)",
          width: "300px",
          zIndex: 99999999,
        }}
      >
        {isVisible ? (
          <Card
            sx={{
              border: "1px solid",
              padding: 1,
              borderRadius: "8px",
              "box-shadow": "0px 0px 8px 6px rgba(149, 191, 255, 1)",
            }}
          >
            <VuiTypography color="white" fontSize={16} mb={2}>
              {id ? activeImageData?.name : defineStoreData?.name}
            </VuiTypography>
            {id ? (
              <>
                {+activeImageData?.value ? (
                  <VuiTypography color="white" fontSize={14}>
                    Tayyor qismlar soni:
                    <VuiTypography sx={{ color: "#f0c819", fontWeight: "700" }} component="span">
                      {Math.floor(+id) === 19 && activeClass === "stellaj_19"
                        ? " " + Math.floor(+activeImageData?.value / 2)
                        : Math.floor(+id) === 19
                        ? " " + Math.floor(+activeImageData?.value / 4)
                        : " " + activeImageData?.value}
                      ta
                    </VuiTypography>
                  </VuiTypography>
                ) : null}
              </>
            ) : (
              <>
                <List sx={{ width: "100%" }} mt={2}>
                  {defineStoreData?.data?.map((item) => (
                    <ListItem sx={{ width: "100%", display: "block", py: 0.5 }}>
                      <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
                        <VuiTypography color="white" fontSize={14}>
                          {item[0]}
                        </VuiTypography>
                        <Stack direction="row" spacing={1}>
                          <VuiTypography color="white" fontSize={14}>
                            {item[1]}
                          </VuiTypography>
                          <VuiTypography color="white" fontSize={14}>
                            {item[2].toLowerCase()}
                          </VuiTypography>
                        </Stack>
                      </Stack>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Card>
        ) : null}
      </VuiBox>
    </div>
  );
}

export default VirtualMap;
