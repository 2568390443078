import { Grid, Card, Button, Box } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useMemo, useState } from "react";
import ProducingProcess from "./components/ProducingProcess";
import CarriageList from "./components/CarriageList";
import { getVagonList } from "api/vagon";
import carriage from "assets/images/carriage_remove_bg.png";
import Loader from "components/Loader";
import VuiTypography from "components/VuiTypography";

function Process() {
  const [vagonList, setVagonList] = useState([]);
  const [miniRows, setMiniRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeVagonNumber, setActiveVagonNumber] = useState();
  const [activeProcess, setActiveProcess] = useState("Hammasi");

  useEffect(() => {
    setLoading(true);
    getVagonList(131).then((data) => {
      const convertedArray = convertArray(data?.e_journal);
      const miniRows = convertArray(data?.e_journal, true);
      setVagonList(convertedArray);
      setActiveVagonNumber(convertedArray?.find((_, index) => index === 0)?.number);
      setMiniRows(miniRows);
      setLoading(false);
    });
  }, []);

  const renderProcessText = (step, problem) => {
    return +step === 8 ? (
      <VuiTypography color="success" fontSize={14}>
        Tayyor
      </VuiTypography>
    ) : problem ? (
      <VuiTypography color="error" fontSize={14}>
        To'xtab qolgan
      </VuiTypography>
    ) : (
      <VuiTypography color="warning" fontSize={14}>
        Jarayonda
      </VuiTypography>
    );
  };

  const activeProcessElements = useMemo(() => {
    if (!miniRows?.length) return [];
    if (activeProcess === "Hammasi") {
      return miniRows;
    } else {
      return miniRows?.filter((item) => item?.process?.props?.children === activeProcess);
    }
  }, [activeProcess, miniRows]);

  // activeProcessElements();

  const activeVagon = useMemo(() => {
    return vagonList?.find((item) => item?.number === activeVagonNumber);
  }, [activeVagonNumber]);

  const convertArray = (array, isMini = false) => {
    if (!array?.length) return [];
    if (isMini) {
      return array[0].json_data.fields.map((_, index) => ({
        number: array[0].json_data.fields[index] || "-",
        type: "Yarim vagon",
        process: renderProcessText(
          array[2].json_data.fields[index].split(" ")[0],
          array[3].json_data?.fields[index]
        ),
        actions: (
          <Button
            onClick={() => setActiveVagonNumber(array[0].json_data.fields[index] || "-")}
            sx={{ textTransform: "none" }}
          >
            Batafsil
          </Button>
        ),
      }));
    }

    return array[0].json_data.fields.map((_, index) => ({
      number: array[0].json_data.fields[index] || "-",
      start_date: array[1].json_data?.fields[index] || "-",
      step: array[2].json_data?.fields[index] || "-",
      problem: array[3].json_data?.fields[index] || "-",
      problem_date: array[4].json_data?.fields[index] || "-",
      finish_date: array[5].json_data?.fields[index] || "-",
      active: 10 || "-",
      actions: <Button sx={{ textTransform: "none" }}>Batafsil</Button>,
    }));
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Card sx={{ height: "35rem" }}>
            <img
              width="100%"
              height="100%"
              style={{ objectFit: "contain" }}
              src={carriage}
              alt=""
            />
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ minHeight: "300px" }}>
            {loading ? (
              <Loader />
            ) : (
              <CarriageList rows={activeProcessElements} setActiveProcess={setActiveProcess} />
            )}
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <ProducingProcess activeCarriage={activeVagon} />
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Process;
