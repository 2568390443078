import { useHttp } from "hooks/use-http";

export const getCategoryList = (id) => {
  const { request } = useHttp();
  return request({ url: `/analytics/spare-parts/by-factory-id/?factory_id=${id}` });
};

export const getCategoryElements = (category) => {
  const { request } = useHttp();
  return request({
    url: `/statics/spare-parts/by-factory-id/?category=${category}`,
  });
};

export const getFactoryList = () => {
  const { request } = useHttp();
  return request({ url: "/analytics/list/factory" });
};

export const getCarriageAnalytics = () => {
  const { request } = useHttp();
  return request({ url: "/analytics/spare-parts/month-by-month/" });
};

export const getPartsAnalytics = (id) => {
  const { request } = useHttp();
  return request({ url: `/analytics/parts/?classificator_element_id=${id}&journal_id=110` });
};

export const getAnalytics = (id) => {
  const { request } = useHttp();
  return request({ url: `/analytics/?classificator_element_id=${id}&journal_id=109` });
};

export const getMainParts = () => {
  const { request } = useHttp();
  return request({ url: `/analytics/main-parts/` });
}

export const getDetailedParts = () => {
  const { request } = useHttp();
  return request({ url: `/analytics/detail-parts/` });
}