import React, { useMemo } from "react";
import { Stack, Grid, Alert, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import GreenLightning from "assets/images/shapes/green-lightning.svg";
import linearGradient from "assets/theme/functions/linearGradient";
import colors from "assets/theme/base/colors";

const CarriageInformations = ({ step, data }) => {
  const { gradients, info } = colors;
  const { cardContent } = gradients;
  const percent = useMemo(() => {
    if (!step) return 0;
    return (+step / 8) * 100;
  }, [step]);

  return (
    <VuiBox display="flex" flexDirection="column">
      <Stack
        spacing="24px"
        background="#fff"
        sx={({ breakpoints }) => ({
          [breakpoints.up("sm")]: {
            flexDirection: "column",
          },
          [breakpoints.up("md")]: {
            flexDirection: "row",
          },
          [breakpoints.only("xl")]: {
            flexDirection: "column",
          },
        })}
      >
        <VuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={({ breakpoints }) => ({
            [breakpoints.only("sm")]: {
              alignItems: "center",
            },
          })}
          alignItems="center"
        >
          <VuiBox sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              variant="determinate"
              value={percent}
              size={200}
              color={percent > 75 ? "success" : percent > 50 ? "warning" : "error"}
            />
            <VuiBox
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <VuiBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <VuiTypography color="white" variant="h2" mt="6px" fontWeight="bold" mb="4px">
                  {percent}%
                </VuiTypography>
                <VuiTypography color="text" variant="caption">
                  Tugatilgan
                </VuiTypography>
              </VuiBox>
            </VuiBox>
          </VuiBox>
          <VuiBox
            display="flex"
            justifyContent="center"
            flexDirection="column"
            sx={{ textAlign: "center" }}
          >
            <VuiTypography color="white" variant="lg" fontWeight="bold" mb="2px" mt="18px">
              {8 - step || 0} ta bosqich
            </VuiTypography>
            <VuiTypography color="text" variant="button" fontWeight="regular">
              To'liq tugaganga qadar
            </VuiTypography>
          </VuiBox>
        </VuiBox>
        <Grid
          container
          sx={({ breakpoints }) => ({
            spacing: "24px",
            [breakpoints.only("sm")]: {
              columnGap: "0px",
              rowGap: "24px",
            },
            [breakpoints.up("md")]: {
              gap: "24px",
              ml: "50px !important",
            },
            [breakpoints.only("xl")]: {
              gap: "12px",
              mx: "auto !important",
            },
          })}
        >
          <Grid item xs={12} md={5.5} xl={5.8} xxl={5.5}>
            <VuiBox
              p="18px"
              alignItems="center"
              sx={{
                background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
                minHeight: "110px",
                borderRadius: "20px",
              }}
            >
              <VuiBox display="flex" flexDirection="column" mr="auto">
                <VuiTypography
                  sx={{ marginBottom: "10px" }}
                  variant="lg"
                  fontSize="1.5rem"
                  color="white"
                  fontWeight="bold"
                >
                  Vagon ma'lumotlari
                </VuiTypography>
                <VuiBox sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <VuiTypography fontSize="1rem" color="text">
                    Vagon raqami:
                  </VuiTypography>
                  <VuiTypography fontSize="18px" fontWeight={600} color="white">
                    {data?.number}
                  </VuiTypography>
                </VuiBox>
                <VuiBox sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <VuiTypography fontSize="1rem" color="text">
                    Ishlab chiqarish boshlangan sanasi:
                  </VuiTypography>
                  <VuiTypography fontSize="16px" fontWeight={600} color="white">
                    {data?.start_date}
                  </VuiTypography>
                </VuiBox>
                <VuiBox sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <VuiTypography fontSize="1rem" color="text">
                    Ishlab chiqarish bosqichi:
                  </VuiTypography>
                  <VuiTypography fontSize="16px" fontWeight={600} color="white">
                    {data?.step?.replace(/\d+/g, "")}
                  </VuiTypography>
                </VuiBox>
                <VuiBox sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <VuiTypography fontSize="1rem" color="text">
                    Yakunlangan sana:
                  </VuiTypography>
                  <VuiTypography fontSize="16px" fontWeight={600} color="white">
                    {data?.finish_date}
                  </VuiTypography>
                </VuiBox>
              </VuiBox>
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={5.5} xl={5.8} xxl={5.5}>
            <VuiBox
              p="18px"
              alignItems="center"
              sx={{
                background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
                minHeight: "110px",
                borderRadius: "20px",
              }}
            >
              <VuiBox display="flex" flexDirection="column" mr="auto">
                <VuiTypography
                  sx={{ marginBottom: "10px" }}
                  variant="lg"
                  fontSize="1.5rem"
                  color="white"
                  fontWeight="bold"
                >
                  Muammo
                </VuiTypography>
                <Alert variant="filled" severity={data?.problem !== "-" ? "error" : "success"}>
                  {data?.problem !== "-" ? data?.problem : "Hech qanday muammo mavjud emas"}
                </Alert>
                <VuiBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    mt: 1,
                    gap: 2,
                  }}
                >
                  <VuiTypography fontSize="1rem" color="white">
                    Muammo sanasi:
                  </VuiTypography>
                  <VuiTypography fontSize="16px" fontWeight={600} color="white">
                    {data?.problem_date}
                  </VuiTypography>
                </VuiBox>
              </VuiBox>
            </VuiBox>
          </Grid>
        </Grid>
      </Stack>
    </VuiBox>
  );
};

export default CarriageInformations;
