/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// Countries flags
import KR from "assets/images/kazakhstan.png";
import AF from "assets/images/afghanistan.png"

export const KZ = [
  {
    davlat: [KR, "Qozog'iston"],
    jami: "20",
    Vagon: "Xopper (Sement)",
    yili: "28.03.2023",
  },
  {
    davlat: [KR, "Qozog'iston"],
    jami: "40",
    vagon: "Xopper (Sement)",
    yili: "15.03.2023",
  },
  {
    davlat: [AF, "Afg'oniston"],
    Jami: "11",
    vagon: "Yopiq yuk vagon",
    yili: "27.01.2023",
  },
];

export const UAE = [
  {
    davlat: [AF, "Afg'oniston"],
    Jami: "10",
    vagon: "Yopiq yuk vagon",
    yili: "14.10.2022",
  },
  {
    davlat: [AF, "Afg'oniston"],
    Jami: "39",
    vagon: "Xopper (Bug'doy) ",
    yili: "21.12.2022",
  },
  {
    davlat: [KR, "Qozog'iston"],
    jami: "40",
    vagon: "Xopper (Sement)",
    yili: "09.08.2023",
  },
];
